import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Contact = () => (
  <Layout>
    <SEO title="Contact Us" />
    <section className="section">
      <div className="container is-centered">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="is-size-1 has-text-centered">
              How Can We Help You?
            </h1>
            <hr />
            <div className="box">
              Hi there,
              <br />
              <br />
              Quality is very important to us and we aim to provide the same
              level of quality in our service that we put into our products. If
              you have any questions you can conract our support at{" "}
              <a href="mailto:info@waggtopia.com">info@waggtopia.com</a>. <br />
              <br />
              Make sure you have your order number if you have any questions
              about an existing order.
              <br />
              <br />
              Thanks,
              <br />
              <br />
              Waggtopia
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Contact
